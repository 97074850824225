<template>
  <div>
    <b-form-group label="Title">
      <b-input v-model="content.title" placeholder="e.g Gavetips" />
    </b-form-group>
    <b-form-group label="Link Text">
      <b-input v-model="content.link_text" placeholder="e.g Se flere Gavetips" />
    </b-form-group>
    <b-form-group label="Link URL">
      <b-input v-model="content.link_url" placeholder="e.g /gavetips" />
    </b-form-group>
    <b-tabs>
      <b-tab
        v-for="(tab, i) in content.tabs"
        :key="i"
        :title="tab.content.title"
        :active="i === currentTab"
        class="position-relative bg-light rounded border p-3"
      >
        <validation-provider name="Tab Title" rules="required" v-slot="context">
          <b-form-group label="Tab Title">
            <b-form-input
              v-model="tab.content.title"
              placeholder="e.g Tab 1"
              :state="getValidationState(context)"
              v-bind="context.ariaInput"
            />

            <b-form-invalid-feedback v-bind="context.ariaMsg">
              {{ context.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <hr v-if="tab.content.widgets.length" />
        <div>
          <div v-for="(subwidget, j) in tab.content.widgets" :key="j">
            <b-row align-h="between">
              <b-col>
                <b-badge variant="secondary">
                  {{ subwidget.type.replace('_', ' ').toUpperCase() }}
                </b-badge>

                <component
                  :key="componentKey"
                  :is="generateComponent(subwidget.type)"
                  v-model="subwidget.content"
                  :resolved="resolved[i]?.resolved[j]?.resolved ?? null"
                />
              </b-col>
              <b-col cols="auto">
                <button v-b-modal="`remove-tab-widget${i}${j}`" class="btn p-0" type="button">
                  <i class="fa fa-times" />
                </button>

                <b-modal :id="`remove-tab-widget${i}${j}`" title="Remove Tab Widget" @ok="removeTabWidget(i, j)">
                  Are you sure you want to remove widget from tab?
                </b-modal>
              </b-col>
            </b-row>
          </div>
        </div>

        <hr />
        <pages-widget-picker
          button-text="Add Tab Widget"
          class="mt-3"
          :omit="['header']"
          @widget="widget => addTabWidget(i, widget)"
        />

        <b-button v-b-modal="`remove-tab-item${i}`" class="position-absolute top-0 right-0" variant="link">
          Remove Tab
        </b-button>
        <b-modal :id="`remove-tab-item${i}`" title="Remove Current Tab" @ok="removeTab(i)">
          Are you sure you want to tab?
        </b-modal>
      </b-tab>

      <template #tabs-end>
        <b-nav-item role="presentation" @click.prevent="addTab">
          <strong>+</strong>
        </b-nav-item>
      </template>

      <template #empty>
        <div class="text-center text-muted p-5">
          There are no open tabs<br />
          Add a new tab using the <strong>+</strong> button above.
        </div>
      </template>
    </b-tabs>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import PagesWidgetPicker from '../PagesWidgetPicker'
import { getValidationState } from '../../page-helpers'
import PagesTabsWidget from './PagesTabsWidget'
import PagesTextWidget from './PagesTextWidget'
import PagesContactFormWidget from './PagesContactFormWidget'
import PagesNewsletterWidget from './PagesNewsletterWidget'
import PagesRelatedProductsWidget from './PagesRelatedProductsWidget'
import PagesToplistWidget from './PagesToplistWidget'
import PagesImageWidget from './PagesImageWidget'
import PagesCategoryToplistWidget from './PagesCategoryToplistWidget'
import PagesStaticTwoColWidget from './PagesStaticTwoColWidget.vue'
import PagesFeaturedCategoriesWidget from './PagesFeaturedCategoriesWidget.vue'
import PagesOneTwoThreeWidget from './PagesOneTwoTheeWidget.vue'
import PagesCustomToplistWidget from './PagesCustomToplistWidget.vue'
import PagesLatestProductsWidget from './PagesLatestProductsWidget.vue'
import PagesVideoWidget from './PagesVideoWidget.vue'
import PagesSustainabilityWidget from './PagesSustainabilityWidget.vue'
import PagesLinksListWidget from './PagesLinksListWidget.vue'
import PagesInspirationToplistWidget from './PagesInspirationToplistWidget.vue'

export default {
  name: 'PagesTabsWidget',
  components: {
    PagesWidgetPicker,
    PagesTabsWidget,
    PagesTextWidget,
    PagesContactFormWidget,
    PagesNewsletterWidget,
    PagesRelatedProductsWidget,
    PagesToplistWidget,
    PagesImageWidget,
    PagesCategoryToplistWidget,
    PagesStaticTwoColWidget,
    PagesFeaturedCategoriesWidget,
    PagesOneTwoThreeWidget,
    PagesCustomToplistWidget,
    PagesLatestProductsWidget,
    PagesVideoWidget,
    PagesSustainabilityWidget,
    PagesLinksListWidget,
    PagesInspirationToplistWidget,
  },
  props: { value: { type: Object }, resolved: { type: Array } },
  data() {
    return { content: this.value, currentTab: 0, componentKey: 0 }
  },
  computed: {
    tabSize() {
      return this.content.tabs.length
    },
  },
  methods: {
    getValidationState,
    generateComponent(type) {
      const component = type
        .split('_')
        .map(c => c.charAt(0).toUpperCase() + c.slice(1))
        .join('')

      return `Pages${component}Widget`
    },
    addTab() {
      const title = `Tab ${this.tabSize + 1}`
      this.content.tabs.push({ type: 'tab_item', content: { title, widgets: [] } })
      this.activateLastTab()
    },
    removeTab(index) {
      this.content.tabs.splice(index, 1)
      this.activateLastTab()
    },
    activateLastTab() {
      this.currentTab = this.tabSize - 1
    },
    addTabWidget(tab, data) {
      console.log(data)
      this.content.tabs[tab].content.widgets.push(data)
    },
    removeTabWidget(tab, index) {
      this.content.tabs[tab].content.widgets.splice(index, 1)
    },
  },
  watch: {
    content: {
      deep: true,
      handler() {
        this.componentKey += 1
      },
    },
  },
}
</script>
