<template>
  <div>
    <b-form-group label="Title">
      <b-input v-model="content.title" placeholder="e.g Awesome Links" />
    </b-form-group>

    <div class="mb-2">Links</div>

    <template v-if="content.links">
      <b-row v-for="(link, index) in content.links" :key="index" class="mb-3">
        <b-col>
          <b-input-group>
            <b-form-input
                placeholder="e.g Wonderful link"
                v-model="link.name"
                style="border-top-right-radius: 0; border-bottom-right-radius: 0;"
            />

            <b-form-input
                placeholder="e.g /link"
                v-model="link.slug"
                style="border-top-right-radius: 0; border-bottom-right-radius: 0;"
            />

            <template #append>
              <b-button
                  variant="danger"
                  @click="() => removeLink(index)"
              >
                <strong>&times;</strong>
              </b-button>
            </template>
          </b-input-group>
        </b-col>
      </b-row>
    </template>

    <b-button variant="light" size="sm" @click="addNewLink">
      Add Link
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'PagesLinksListWidget',

  props: {
    value: {
      type: Object
    },
  },

  data() {
    return {
      content: this.value,
    }
  },

  methods: {
    addNewLink() {
      this.content.links.push({ name: '', slug: '' })
    },

    removeLink(index) {
      if (this.content.links.length === 1) {
        this.content.links = []

        return
      }

      this.content.links.splice(index, 1)
    },
  },
}
</script>

<style scoped>
.no-dimensions {
  width: 0;
  height: 0;
}
</style>
