<template>
  <div>
    <b-form-group label="Link Title">
      <b-input v-model="content.link_title" placeholder="e.g Awesome Video" />
    </b-form-group>

    <b-form-group label="Link">
      <b-input v-model="content.link" placeholder="e.g awesome-video" />
    </b-form-group>

    <b-form-group label="Starting index">
      <b-input v-model="content.starting_index" placeholder="e.g 1" type="number" min="1" />
    </b-form-group>

    <div class="mb-2">Products</div>

    <template v-if="content.products">
      <b-row v-for="(product, index) in content.products" :key="index" class="mb-3">
        <b-col>
          <b-input-group>
            <b-form-input
                placeholder="ID, e.g. 1"
                v-model="product.id"
                style="border-top-right-radius: 0; border-bottom-right-radius: 0; max-width: 77px;"
            />

            <b-form-input
              placeholder="Short text e.g Wonderful product"
              v-model="product.text"
              style="border-top-right-radius: 0; border-bottom-right-radius: 0;"
            />

            <template #append>
              <b-button
                  variant="danger"
                  @click="() => removeProduct(index)"
              >
                <strong>&times;</strong>
              </b-button>
            </template>
          </b-input-group>
        </b-col>
      </b-row>
    </template>

    <b-button variant="light" size="sm" @click="addNewProduct">
      Add Product
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'InspirationToplistWidget',

  props: {
    value: {
      type: Object
    },
  },

  data() {
    return {
      content: this.value,
    }
  },

  methods: {
    addNewProduct() {
      this.content.products.push({ id: '', text: '' })
    },

    removeProduct(index) {
      if (this.content.products.length === 1) {
        this.content.products = []

        return
      }

      this.content.products.splice(index, 1)
    },
  },
}
</script>
