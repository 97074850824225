<template>
  <validation-observer v-slot="{ invalid }">
    <form @submit.prevent="submitFormData">
      <div id="content" class="app-content">
        <!-- BEGIN container -->
        <b-container>
          <!-- BEGIN row -->
          <b-row class="justify-content-center">
            <!-- BEGIN col-10 -->
            <b-col xl="12">
              <!-- BEGIN row -->
              <b-row>
                <!-- BEGIN col-9 -->
                <b-col xl="12">
                  <b-row>
                    <b-col>
                      <b-breadcrumb>
                        <b-breadcrumb-item :to="{ name: 'inspirations' }">
                          INSPIRATIONS
                        </b-breadcrumb-item>
                        <b-breadcrumb-item active>
                          {{ breadcrumbTitle }}
                        </b-breadcrumb-item>
                      </b-breadcrumb>
                    </b-col>
                    <b-col cols="auto">
                      <b-button variant="default" size="sm" type="submit" :disabled="invalid || isLoading">
                        {{ buttonText }}
                        <b-spinner v-show="isLoading" class="ml-2" small label="Loading" />
                      </b-button>
                    </b-col>
                  </b-row>

                  <hr />

                  <b-card class="mb-3">
                    <label for="inspiration-title">Title</label>
                    <validation-provider name="Title" v-slot="context" rules="required">
                      <b-input-group label="Title">
                        <b-form-input
                          id="inspiration-title"
                          v-model="title"
                          placeholder="e.g. Product Inspiration"
                          :state="getValidationState(context)"
                          v-bind="context.ariaInput"
                        />

                        <b-form-invalid-feedback v-bind="context.ariaMsg">
                          {{ context.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </validation-provider>

                    <template v-if="isEditing">
                      <label class="mt-3">Slug</label>
                      <b-form-input
                        :value="inspiration.slug"
                        disabled
                      />
                    </template>
                  </b-card>

                  <b-card>
                    <b-row align-v="center" v-if="isEditing">
                      <b-col>
                        <b-form-group label="SEO Title">
                          <b-form-input v-model="meta.title" placeholder="e.g 600 Opplevelser" />
                        </b-form-group>
                        <b-form-group label="SEO Description">
                          <b-form-input v-model="meta.description" placeholder="e.g lorem porem" />
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <template v-if="!isEditing">
                      <label for="inspiration-slug">Custom Inspiration Slug</label>
                      <validation-provider name="Slug" v-slot="context" rules="required">
                        <b-input-group label="Slug" class="mb-3">
                          <b-form-input
                              id="inspiration-slug"
                              v-model="slug"
                              placeholder="e.g product-inspiration"
                              :state="getValidationState(context)"
                              v-bind="context.ariaInput"
                          />

                          <b-form-invalid-feedback v-bind="context.ariaMsg">
                            {{ context.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-input-group>
                      </validation-provider>
                    </template>

                    <b-form-group label="Published Status">
                      <b-form-radio-group
                          v-model="published_status"
                          :options="published_status_options"
                          class="w-100"
                          button-variant="primary"
                          buttons
                      />
                    </b-form-group>

                    <b-row v-if="published_status === 'scheduled'">
                      <b-col>
                        <validation-provider name="Schedule publish date" v-slot="context" rules="required">
                          <b-form-group label="Schedule publish date">
                            <b-form-datepicker
                                locale="nb-NO"
                                :start-weekday="1"
                                v-model="publish_date"
                                :state="getValidationState(context)"
                                v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <b-col>
                        <validation-provider name="Schedule publish time" v-slot="context" rules="required">
                          <b-form-group label="Schedule publish time">
                            <b-form-timepicker
                                locale="nb-NO"
                                v-model="publish_time"
                                :state="getValidationState(context)"
                                v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-card>

                  <hr v-if="content.length" />

                  <pages-widgets-list :initial-content="content" @content="updateContent" />
                  <hr />
                  <pages-widget-picker @widget="addWidget" />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { inspirations } from '@/api'
import { getValidationState, convertDateTime } from '../page-helpers'
import PagesWidgetsList from './../Pages/PagesWidgetsList'
import PagesWidgetPicker from './../Pages/PagesWidgetPicker'

export default {
  name: 'InspirationsFormPage',

  inject: ['$cannot'],

  components: { PagesWidgetsList, PagesWidgetPicker },

  data() {
    return {
      inspiration: {},
      published_status: 'in_progress',
      published_status_options: [
        { value: 'published', text: 'Published' },
        { value: 'scheduled', text: 'Scheduled' },
        { value: 'not_published', text: 'Not published' },
        { value: 'in_progress', text: 'In progress' },
      ],
      slug: '',
      content: [],
      title: '',
      meta: {
        title: '',
        description: '',
        no_index: false,
      },
      publish_date: '',
      publish_time: '',
      isLoading: false,
    }
  },

  beforeRouteEnter(from, to, next) {
    next(vm => vm.loadContent())
  },

  computed: {
    isEditing() {
      return this.$route.name === 'inspirations.edit'
    },

    breadcrumbTitle() {
      return this.isEditing ? 'EDIT' : 'NEW'
    },

    buttonText() {
      return `${this.isEditing ? 'Save' : 'Create'} Inspiration`
    },
  },

  methods: {
    getValidationState,

    async loadContent() {
      if (!this.isEditing) return

      this.$store.dispatch('inspirations/find', this.$route.params.id).then(inspiration => {
        const publishedAt = convertDateTime(inspiration.published_at);

        this.inspiration = inspiration
        this.name = inspiration.name
        this.slug = inspiration.slug
        this.title = inspiration.title
        this.meta.title = inspiration.meta?.meta_title
        this.meta.description = inspiration.meta?.meta_description
        this.meta.no_index = inspiration.meta?.no_index
        this.published_status = inspiration.published_status
        this.publish_date = publishedAt.date
        this.publish_time = publishedAt.time
        this.content = inspiration.content
        this.content.map(widget => widget.id = widget.id ?? uuidv4())
      })
    },

    getPublishedAt() {
      if (this.published_status === 'published') {
        const currentDate = new Date()
        const year = currentDate.getFullYear()
        const month = String(currentDate.getMonth() + 1).padStart(2, '0')
        const day = String(currentDate.getDate()).padStart(2, '0')
        const hours = String(currentDate.getHours()).padStart(2, '0')
        const minutes = String(currentDate.getMinutes()).padStart(2, '0')
        const seconds = String(currentDate.getSeconds()).padStart(2, '0')

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
      }

      if (this.published_status === 'scheduled') {
        return `${this.publish_date} ${this.publish_time}`
      }

      return null
    },

    async submitFormData() {
      this.isLoading = true
      const data = {
        name: this.name,
        content: this.content,
        title: this.title,
        meta: this.meta,
        published_at: this.getPublishedAt(),
        in_progress: this.published_status === 'in_progress',
      }

      if (!this.isEditing) {
        try {
          data.slug = this.slug
          const response = await inspirations.create(data)
          this.$router.push({name: 'inspirations.edit', params: {id: response.data.id}})
          this.$bvToast.toast(`Inspiration with slug ${this.name} created.`, {
            title: 'Inspiration Created',
            variant: 'primary',
          })
        } catch (error) {
          this.$bvToast.toast(error.message, {
            title: 'Something went wrong...',
            variant: 'danger',
          })
        }
      } else {
        try {
          await inspirations.update(this.inspiration.id, data)
          this.$bvToast.toast(`Inspiration with slug ${this.slug} updated.`, {
            title: 'Inspiration updated',
            variant: 'primary',
          })
        } catch (error) {
          this.$bvToast.toast(error.message, {
            title: 'Something went wrong...',
            variant: 'danger',
          })
        }
      }

      this.isLoading = false
    },

    addWidget(widget) {
      this.$nextTick(() => this.content.push(widget))
    },

    updateContent(content) {
      this.content = content
    },
  },
}
</script>
