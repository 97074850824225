<template>
  <draggable v-model="content" handle=".handle" :group="{ name: 'widgets' }">
    <b-row v-for="(widget, index) in content" :key="widget.id">
      <b-col>
        <b-card class="mb-4">
          <b-badge variant="secondary" class="mb-2">
            {{ widget.type.replaceAll('_', ' ').toUpperCase() }}
          </b-badge>

          <component
            :is="generateComponent(widget.type)"
            v-model="widget.content"
            :resolved="widget.resolved"
          />
        </b-card>
      </b-col>

      <b-col cols="auto">
        <button class="btn p-0 handle" title="Drag" type="button">
          <i class="fa fa-align-justify" />
        </button>
        <br />
        <button v-b-modal="`remove-widget${index}`" class="btn p-0" type="button">
          <i class="fa fa-times" />
        </button>

        <b-modal :id="`remove-widget${index}`" title="Remove Widget" @ok="removeWidget(index)">
          Are you sure you want to remove widget?
        </b-modal>
      </b-col>
    </b-row>
  </draggable>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import Draggable from 'vuedraggable'
import PagesTabsWidget from './components/PagesTabsWidget'
import PagesTextWidget from './components/PagesTextWidget'
import PagesContactFormWidget from './components/PagesContactFormWidget'
import PagesNewsletterWidget from './components/PagesNewsletterWidget'
import PagesRelatedProductsWidget from './components/PagesRelatedProductsWidget'
import PagesToplistWidget from './components/PagesToplistWidget'
import PagesImageWidget from './components/PagesImageWidget'
import PagesCategoryToplistWidget from './components/PagesCategoryToplistWidget'
import PagesOneTwoThreeWidget from './components/PagesOneTwoTheeWidget'
import PagesFeaturedCategoriesWidget from './components/PagesFeaturedCategoriesWidget'
import PagesFeaturedPlacesWidget from './components/PagesFeaturedPlacesWidget'
import PagesFeaturedPlacesInNorwayWidget from './components/PagesFeaturedPlacesInNorwayWidget'
import PagesStaticTwoColWidget from './components/PagesStaticTwoColWidget.vue'
import PagesStaticGiftcardWidget from './components/PagesStaticGiftcardWidget.vue'
import PagesReviewSlideshowWidget from './components/PagesReviewSlideshowWidget.vue'
import PagesPlaceListWidget from './components/PagesPlaceListWidget.vue'
import PagesCategoryListWidget from './components/PagesCategoryListWidget.vue'
import PagesDynamicToplistWidget from './components/PagesDynamicToplistWidget.vue'
import PagesDynamicSeoBlockWidget from './components/PagesDynamicSeoBlockWidget.vue'
import PagesCustomToplistWidget from './components/PagesCustomToplistWidget.vue'
import PagesLatestProductsWidget from './components/PagesLatestProductsWidget.vue'
import PagesVideoWidget from './components/PagesVideoWidget.vue'
import PagesSustainabilityWidget from './components/PagesSustainabilityWidget.vue'
import PagesLinksListWidget from './components/PagesLinksListWidget.vue'
import PagesInspirationToplistWidget from './components/PagesInspirationToplistWidget.vue'

export default {
  name: 'PagesWidgetsList',
  components: {
    Draggable,
    PagesTabsWidget,
    PagesTextWidget,
    PagesContactFormWidget,
    PagesNewsletterWidget,
    PagesRelatedProductsWidget,
    PagesToplistWidget,
    PagesImageWidget,
    PagesCategoryToplistWidget,
    PagesOneTwoThreeWidget,
    PagesFeaturedCategoriesWidget,
    PagesFeaturedPlacesWidget,
    PagesFeaturedPlacesInNorwayWidget,
    PagesStaticTwoColWidget,
    PagesStaticGiftcardWidget,
    PagesReviewSlideshowWidget,
    PagesPlaceListWidget,
    PagesCategoryListWidget,
    PagesDynamicToplistWidget,
    PagesDynamicSeoBlockWidget,
    PagesCustomToplistWidget,
    PagesLatestProductsWidget,
    PagesVideoWidget,
    PagesSustainabilityWidget,
    PagesLinksListWidget,
    PagesInspirationToplistWidget,
  },
  props: { initialContent: Array },
  data() {
    return { content: [] }
  },
  watch: {
    initialContent(content) {
      this.content = content
    },
    content(content) {
      this.update(content)
    },
  },
  methods: {
    generateComponent(type) {
      const component = type
        .split('_')
        .map(c => c.charAt(0).toUpperCase() + c.slice(1))
        .join('')

      return `Pages${component}Widget`
    },
    removeWidget(index) {
      this.content.splice(index, 1)
      this.update()
    },
    handleRenderUpdate(index, data) {
      this.content.splice(index, 1, data)
      this.update()
    },
    update(content = null) {
      this.$emit('content', content ?? this.content)
    },
  },
}
</script>
