<template>
  <div>
    <b-form-group label="Title">
      <b-input v-model="content.title" placeholder="e.g Awesome Video" />
    </b-form-group>

    <b-form-group label="Text">
      <b-input v-model="content.text" placeholder="e.g lorem ipsum" />
    </b-form-group>

    <div class="mb-2">Bullet Points</div>

    <template v-if="content.bullet_points">
      <b-row v-for="(point, index) in content.bullet_points" :key="index" class="mb-3">
        <b-col>
          <b-input-group>
            <b-form-input
                placeholder="e.g Wonderful tracks"
                v-model="content.bullet_points[index].text"
                style="border-top-right-radius: 0; border-bottom-right-radius: 0;"
            />

            <template #append>
              <b-button
                  variant="danger"
                  @click="() => removeBulletPoint(index)"
              >
                <strong>&times;</strong>
              </b-button>
            </template>
          </b-input-group>
        </b-col>
      </b-row>
    </template>

    <b-button variant="light" size="sm" @click="addEmptyBulletPoint">
      Add Point
    </b-button>

    <div class="mb-2 mt-3">Videos</div>

    <input
        class="invisible no-dimensions"
        accept="video/mp4,video/x-m4v,video/*"
        ref="desktopVideo"
        type="file"
        @change="uploadDesktopVideo"
    />
    <b-button variant="primary" class="mb-2" @click="pickDesktopVideo" :disabled="uploading.desktop">
      <b-spinner v-show="uploading.desktop" class="mr-2" small label="Loading" />
      {{ content.video.desktop ? 'Upload different video' : 'Upload video' }}
    </b-button>

    <div class="w-25 h-auto my-2">
      <b-embed
          type="video"
          :src="content.video.desktop"
          allowfullscreen
          controls
          v-if="content.video.desktop"
      />
    </div>

    <b-form-checkbox v-model="content.video.tablet_same_as_desktop" @change="toggleSameVideoForTablet">
      Use the same video for tablets
    </b-form-checkbox>

    <div class="mt-2" v-if="!content.video.tablet_same_as_desktop">
      <input
          class="invisible no-dimensions"
          accept="video/mp4,video/x-m4v,video/*"
          ref="tabletVideo"
          type="file"
          @change="uploadTabletVideo"
      />
      <b-button variant="primary" class="mb-2" @click="pickTabletVideo" :disabled="uploading.tablet">
        <b-spinner v-show="uploading.tablet" class="mr-2" small label="Loading" />
        {{ content.video.tablet ? 'Upload different video' : 'Upload video' }}
      </b-button>

      <div class="w-25 h-auto my-2">
        <b-embed
            type="video"
            :src="content.video.tablet"
            allowfullscreen
            controls
            v-if="content.video.tablet"
        />
      </div>
    </div>

    <b-form-checkbox v-model="content.video.mobile_same_as_desktop" @change="toggleSameVideoForMobile">
      Use the same video for mobile
    </b-form-checkbox>

    <div class="mt-2" v-if="!content.video.mobile_same_as_desktop">
      <input
          class="invisible no-dimensions"
          accept="video/mp4,video/x-m4v,video/*"
          ref="mobileVideo"
          type="file"
          @change="uploadMobileVideo"
      />
      <b-button variant="primary" class="mb-2" @click="pickMobileVideo" :disabled="uploading.mobile">
        <b-spinner v-show="uploading.mobile" class="mr-2" small label="Loading" />
        {{ content.video.mobile ? 'Upload different video' : 'Upload video' }}
      </b-button>

      <div class="w-25 h-auto my-2">
        <b-embed
            type="video"
            :src="content.video.mobile"
            allowfullscreen
            controls
            v-if="content.video.mobile"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { media } from '@/api'

export default {
  name: 'PagesVideoWidget',

  props: {
    value: {
      type: Object
    },
  },

  data() {
    return {
      content: this.value,
      uploading: {
        desktop: false,
        tablet: false,
        mobile: false,
      },
    }
  },

  methods: {
    addEmptyBulletPoint() {
      this.content.bullet_points.push({ text: '' })
    },

    removeBulletPoint(index) {
      if (this.content.bullet_points.length === 1) {
        this.content.bullet_points = []

        return
      }

      this.content.bullet_points.splice(index, 1)
    },

    toggleSameVideoForTablet(value) {
      this.content.video.tablet_same_as_desktop = value

      if (!value) {
        this.content.video.tablet = null
      }
    },

    toggleSameVideoForMobile(value) {
      this.content.video.mobile_same_as_desktop = value

      if (!value) {
        this.content.video.mobile = null
      }
    },

    pickDesktopVideo() {
      this.$refs.desktopVideo.click()
    },

    pickTabletVideo() {
      this.$refs.tabletVideo.click()
    },

    pickMobileVideo() {
      this.$refs.mobileVideo.click()
    },

    async uploadDesktopVideo(event) {
      try {
        this.uploading.desktop = true
        const video = await media.video(event.target.files[0], 'video-block')
        this.content.video.desktop = video.data.url
        this.uploading.desktop = false
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: 'Something went wrong.',
          variant: 'danger',
        })
      }
    },

    async uploadTabletVideo(event) {
      try {
        this.uploading.tablet = true
        const video = await media.video(event.target.files[0], 'video-block')
        this.content.video.tablet = video.data.url
        this.uploading.tablet = false
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: 'Something went wrong.',
          variant: 'danger',
        })
      }
    },

    async uploadMobileVideo(event) {
      try {
        this.uploading.mobile = true
        const video = await media.video(event.target.files[0], 'video-block')
        this.content.video.mobile = video.data.url
        this.uploading.mobile = false
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: 'Something went wrong.',
          variant: 'danger',
        })
      }
    },
  },
}
</script>

<style scoped>
.no-dimensions {
  width: 0;
  height: 0;
}
</style>
