<template>
  <p>Shows 10 latest products with the title "Nyheter {{ new Date().getFullYear() }}" (year is dynamic).</p>
</template>

<script>
export default {
  name: 'PagesLatestProductsWidget',
  props: { value: { type: String } },
  data() {
    return { content: 'static' }
  },
}
</script>
