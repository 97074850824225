<template>
  <div>
    <b-form-group label="Title">
      <b-input v-model="content.title" placeholder="e.g Den bærekraftige gaven" />
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'PagesSustainabilityWidget',

  props: { value: { type: Object } },

  data() {
    return {
      content: this.value,
      resolved: {
        type: Array
      },
    }
  },
}
</script>
