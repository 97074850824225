<template>
  <validation-observer v-slot="{ invalid }">
    <form @submit.prevent="submitFormData">
      <div id="content" class="app-content">
        <!-- BEGIN container -->
        <b-container>
          <!-- BEGIN row -->
          <b-row class="justify-content-center">
            <!-- BEGIN col-10 -->
            <b-col xl="12">
              <!-- BEGIN row -->
              <b-row>
                <!-- BEGIN col-9 -->
                <b-col xl="12">
                  <b-row>
                    <b-col>
                      <b-breadcrumb>
                        <b-breadcrumb-item :to="{ name: 'pages' }">
                          PAGES
                        </b-breadcrumb-item>
                        <b-breadcrumb-item active>
                          {{ breadcrumbTitle }}
                        </b-breadcrumb-item>
                      </b-breadcrumb>
                    </b-col>
                    <b-col cols="auto">
                      <b-button variant="default" size="sm" type="submit" :disabled="invalid || isLoading">
                        {{ buttonText }}
                        <b-spinner v-show="isLoading" class="ml-2" small label="Loading" />
                      </b-button>
                    </b-col>
                  </b-row>

                  <hr />

                  <b-card class="mb-3">
                    <validation-provider name="Name" v-slot="context" rules="required">
                      <b-input-group label="Name">
                        <b-form-input
                          v-model="name"
                          placeholder="e.g Main categories"
                          :state="getValidationState(context)"
                          v-bind="context.ariaInput"
                        />

                        <b-form-invalid-feedback v-bind="context.ariaMsg">
                          {{ context.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </validation-provider>
                  </b-card>

                  <b-card v-if="isEditPage">
                    <b-row align-v="center">
                      <b-col>
                        <h3>{{ page.slug }}</h3>
                        <b-form-group v-if="type === 'page'" label="Page Title (Internal)">
                          <b-form-input v-model="title" placeholder="e.g Front Page" />
                        </b-form-group>
                        <b-form-group v-if="type === 'page'" label="SEO Title">
                          <b-form-input v-model="meta.title" placeholder="e.g 600 Opplevelser" />
                        </b-form-group>
                        <b-form-group v-if="type === 'page'" label="SEO Description">
                          <b-form-input v-model="meta.description" placeholder="e.g lorem porem" />
                        </b-form-group>
                      </b-col>
                      <b-col cols="auto">
                        <b-badge :variant="page.is_default ? 'success' : 'secondary'">
                          {{ page.is_default ? 'Default' : 'Custom' }} Template
                        </b-badge>
                      </b-col>
                    </b-row>
                  </b-card>
                  <b-card v-else>
                    <b-form-group label="Page Type">
                      <b-form-radio-group
                        v-model="type"
                        :options="options"
                        class="w-100"
                        button-variant="primary"
                        buttons
                        @change="handleTypeRadioChange"
                      />
                    </b-form-group>

                    <template v-if="type === 'page'">
                      <label>Custom Page Slug</label>
                      <validation-provider name="Slug" v-slot="context" rules="required">
                        <b-input-group label="Slug" prepend="page/">
                          <b-form-input
                            v-model="slug"
                            placeholder="e.g front-page"
                            :state="getValidationState(context)"
                            v-bind="context.ariaInput"
                          />

                          <b-form-invalid-feedback v-bind="context.ariaMsg">
                            {{ context.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-input-group>
                      </validation-provider>
                    </template>

                    <validation-provider v-if="type === 'product'" name="Product" v-slot="context" rules="required">
                      <multi-select
                        v-model="linked"
                        placeholder="Pick a product"
                        track-by="name"
                        label="name"
                        :options="productOptions"
                        :searchable="true"
                        @open="findProduct"
                        @search-change="findProduct"
                      />

                      <small v-if="context.errors.length" v-bind="context.ariaMsg" class="text-danger form-text">
                        {{ context.errors[0] }}
                      </small>
                    </validation-provider>

                    <validation-provider v-if="type === 'category'" name="Category" v-slot="context" rules="required">
                      <multi-select
                        v-model="linked"
                        placeholder="Pick a category"
                        track-by="name"
                        label="name"
                        :options="categoryOptions"
                        :searchable="true"
                        @open="findCategory"
                        @search-change="findCategory"
                      />

                      <small v-if="context.errors.length" v-bind="context.ariaMsg" class="text-danger form-text">
                        {{ context.errors[0] }}
                      </small>
                    </validation-provider>

                    <validation-provider v-if="type === 'gifttip'" name="Gifttip" v-slot="context" rules="required">
                      <multi-select
                        v-model="linked"
                        placeholder="Pick a gifttip"
                        track-by="name"
                        label="name"
                        :options="gifttipOptions"
                        :searchable="true"
                        @open="findGiftTip"
                        @search-change="findGiftTip"
                      />

                      <small v-if="context.errors.length" v-bind="context.ariaMsg" class="text-danger form-text">
                        {{ context.errors[0] }}
                      </small>
                    </validation-provider>

                    <validation-provider v-if="type === 'place'" name="Place" v-slot="context" rules="required">
                      <multi-select
                        v-model="linked"
                        placeholder="Pick a place"
                        track-by="name"
                        label="name"
                        :options="placeOptions"
                        @open="findPlace"
                      />

                      <small v-if="context.errors.length" v-bind="context.ariaMsg" class="text-danger form-text">
                        {{ context.errors[0] }}
                      </small>
                    </validation-provider>
                  </b-card>

                  <hr v-if="content.length" />

                  <pages-widgets-list :initial-content="content" @content="updateContent" />
                  <hr />
                  <pages-widget-picker @widget="addWidget" />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'

import { v4 as uuidv4 } from 'uuid'
import MultiSelect from 'vue-multiselect'
import { products, categories, places, pages } from '@/api'
import { getValidationState } from '../page-helpers'
import PagesWidgetsList from './PagesWidgetsList'
import PagesWidgetPicker from './PagesWidgetPicker'

export default {
  name: 'PagesFormPage',
  inject: ['$cannot'],
  components: { MultiSelect, PagesWidgetsList, PagesWidgetPicker },
  data() {
    return {
      type: 'product',
      linked: null,
      options: [
        { value: 'page', text: 'Page' },
        { value: 'product', text: 'Product' },
        { value: 'category', text: 'Category' },
        { value: 'gifttip', text: 'Gifttip' },
        { value: 'place', text: 'Place' },
      ],

      /** Options */
      productOptions: [],
      categoryOptions: [],
      gifttipOptions: [],
      placeOptions: [],

      /** Data */
      page: {},
      slug: '',
      content: [],
      title: '',
      name: '',
      meta: {
        title: '',
        description: '',
        no_index: false,
      },
      isLoading: false
    }
  },
  beforeRouteEnter(from, to, next) {
    next(vm => vm.loadContent())
  },
  computed: {
    isEditPage() {
      return this.$route.name === 'pages.edit'
    },
    pageTitle() {
      return this.isEditPage ? 'Edit Product' : 'New Product'
    },
    breadcrumbTitle() {
      return this.isEditPage ? 'EDIT' : 'NEW'
    },
    buttonText() {
      return `${this.isEditPage ? 'Save' : 'Create'} Page`
    },
    shouldSubmit() {
      return this.type === 'page' ? this.slug.length > 0 : this.linked.length > 0
    },
  },
  methods: {
    getValidationState,
    async loadContent() {
      if (!this.isEditPage) return

      this.$store.dispatch('pages/find', this.$route.params.id).then(page => {
        this.page = page
        this.name = page.name
        this.slug = page.slug
        this.title = page.title
        this.meta.title = page.meta?.meta_title
        this.meta.description = page.meta?.meta_description
        this.meta.no_index = page.meta?.no_index
        this.type = page.slug.split('/')[0]
        this.content = page.content
        this.linked = page[this.type]
        this.content.map(widget => widget.id = widget.id ?? uuidv4())
      })
    },
    async findProduct(name = '') {
      const response = await products.all({ filter: { name } })

      this.productOptions = await response.data()
    },
    async findCategory(name = '') {
      const response = await categories.all({ filter: { name, type: 'category' } })

      this.categoryOptions = await response.data()
    },
    async findGiftTip(name = '') {
      const response = await categories.all({ filter: { name, type: 'gifttips' } })

      this.gifttipOptions = (await response.data()).filter((gifttip) => gifttip.parent)
    },
    async findPlace() {
      const response = await places.all()

      this.placeOptions = await response.data()
    },
    handleTypeRadioChange(type) {
      this.linked = this.type !== type ? {} : this.page[this.type]
    },
    getSlug() {
      if (this.type === 'page' || this.linked === undefined) return `page/${this.slug}`
      if (this.type === 'place') return `${this.type}/${this.linked.id}`
      return `${this.type}/${this.linked.slug}`
    },
    async submitFormData() {
      this.isLoading = true;

      const data = {name: this.name, slug: this.getSlug(), content: this.content, title: this.title, meta: this.meta }

      if (!this.isEditPage) {
        const response = await pages.create(data)
        this.$router.push({ name: 'pages.edit', params: { id: response.data.id } })
        this.$bvToast.toast(`Page with slug ${this.name} created.`, {
          title: 'Page Created',
          variant: 'primary',
        })
      } else {
        await pages.update(this.page.id, data)
        this.$bvToast.toast(`Page with slug ${this.name} updated.`, {
          title: 'Page updated',
          variant: 'primary',
        })
      }

      this.isLoading = false;
    },
    addWidget(widget) {
      this.$nextTick(() => this.content.push(widget))
    },
    updateContent(content) {
      this.content = content
    },
  },
}
</script>
