<template>
  <div>
    <b-form-group label="Title">
      <b-input v-model="content.title" placeholder="e.g Kategorier" />
    </b-form-group>

    <b-form-group label="Link Text (optional)">
      <b-input v-model="content.link_text" placeholder="e.g Se flere kategorier" />
    </b-form-group>

    <b-form-group label="Link URL (optional)">
      <b-input v-model="content.link_url" placeholder="e.g /kategori" />
    </b-form-group>

    <b-form-group label="Product ID's">
      <b-input v-model="content.product_ids" placeholder="e.g 1,2,3" />
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'PagesCustomToplistWidget',

  props: { value: { type: Object } },

  data() {
    return {
      content: this.value,
      resolved: {
        type: Array
      },
    }
  },
}
</script>
