<template>
  <div>
    <template v-if="content.id || selected?.url">
      <b-img :src="content.src ?? selected.url" alt="" fluid-grow thumbnail rounded />
      <hr />
    </template>

    <b-row align-h="end">
      <b-col cols="auto">
        <b-button v-if="content.id" variant="danger" @click="deleteImg" class="mr-2">Delete image</b-button>

        <b-button variant="primary" v-b-modal="modal"> {{ content.id ? 'Change' : 'Upload' }} image </b-button>

        <b-modal title="Pick Image" :id="modal" hide-footer>
          <image-browser prefix="page" @select="handleSelect" />
        </b-modal>
      </b-col>
    </b-row>

    <b-form-group label="Caption">
      <b-input v-model="content.caption" placeholder="e.g Awesome Image" />
    </b-form-group>
  </div>
</template>

<script>
import ImageBrowser from '../../../components/ImageBrowser'

export default {
  name: 'PagesImageWidget',
  components: { ImageBrowser },
  props: { value: { type: Object }, resolved: { type: Object } },
  data() {
    return { content: this.value, selected: this.resolved }
  },
  computed: {
    modal() {
      return (
        'image-browser-' +
        Math.random()
          .toString(36)
          .substr(2, 9)
      )
    },
  },
  methods: {
    handleSelect(selected) {
      this.content.id = selected.id;
      this.content.src = selected.url;
      this.selected = selected
      this.$bvModal.hide(this.modal)
    },

    deleteImg() {
      delete this.content.id;
      this.content.src = null;
      this.selected = null
      this.$bvModal.hide(this.modal)
    }
  },
}
</script>
