<template>
  <div id="content" class="app-content">
    <!-- page header -->
    <b-row>
      <b-col>
        <h1 class="page-header">Inspirations <small>all listings.</small></h1>
      </b-col>

      <b-col sm="auto" class="ml-auto">
        <b-button variant="default" :to="{ name: 'inspirations.new' }" :disabled="$cannot('inspirations.manage')">
          New Inspiration
        </b-button>
      </b-col>
    </b-row>

    <!-- page content -->
    <b-card>
      <b-table :fields="fields" :items="listing" show-empty stacked="sm" bordered hover>
        <template v-slot:cell(options)="row">
          <b-dropdown variant="default" size="sm" text="Options" offset="25" right>
            <b-dropdown-item
              size="sm"
              :to="{ name: 'inspirations.edit', params: { id: row.item.id } }"
              :disabled="$cannot('inspirations.manage')"
            >
              Edit
            </b-dropdown-item>

            <b-dropdown-item
              v-b-modal="`inspiration-${row.item.id}`"
              size="sm"
              :disabled="$cannot('inspirations.manage')"
            >
              Delete
            </b-dropdown-item>

            <b-modal :id="`inspiration-${row.item.id}`" title="Delete Inspiration" @ok="deleteInspiration(row.item.id)">
              Are you sure you want to delete this inspiration?
            </b-modal>
          </b-dropdown>
        </template>

        <template v-slot:cell(slug)="{ item }">
          <b-link :href="getInspirationLink(item)" target="_blank">{{ item.slug }}</b-link>
        </template>
      </b-table>

      <!-- pagination -->
      <b-pagination
        v-model="meta.current_page"
        :total-rows="meta.total"
        :per-page="meta.per_page"
        @change="paginationChange"
      />
    </b-card>
  </div>
</template>

<script>
import { formatDate } from '../page-helpers'

export default {
  name: 'InspirationsPage',
  inject: ['$cannot'],

  beforeRouteEnter(from, to, next) {
    next(vm => vm.loadContent())
  },

  data() {
    return {
      listing: [],
      meta: {
        current_page: 1,
        from: 0,
        last_page: 1,
        path: '',
        per_page: 0,
        to: 0,
        total: 0,
      },
    }
  },

  computed: {
    fields() {
      return [
        { key: 'id', sortable: false, label: 'ID' },
        { key: 'title', sortable: false },
        { key: 'slug', sortable: false },
        { key: 'published_status', sortable: false, label: 'Published status', formatter: value => this.formatPublishStatus(value)},
        { key: 'updated_at', sortable: false, label: 'Last update', formatter: value => formatDate(value) },
        { key: 'options', sortable: false, label: '' },
      ]
    },
  },

  methods: {
    async loadContent() {
      const page = this.$route.query.page ?? 1
      const response = await this.$store.dispatch('inspirations/listing', page)

      this.$nextTick(() => {
        this.listing = response.data()
        this.meta = response.meta
      })
    },

    formatPublishStatus(status) {
      switch (status) {
        case 'published':
          return 'Published'
        case 'scheduled':
          return 'Scheduled'
        case 'not_published':
          return 'Not published'
        case 'in_progress':
          return 'In progress'
        default:
          return 'Unknown'
      }
    },

    paginationChange(page) {
      this.$router.push(page === 1 ? { name: 'pages' } : { name: 'pages', query: { page } })
      this.loadContent()
    },

    paginationLinkGenerator(page) {
      return page === 1 ? { name: 'pages' } : { name: 'pages', query: { page } }
    },

    async deletePage(id) {
      await this.$store.dispatch('inspiration/delete', id)
      await this.loadContent()
    },

    getInspirationLink(inspiration) {
      return `${process.env.VUE_APP_APP_URL}/inspirasjon/${inspiration.slug}`
    },
  },
}
</script>
